import HOST from "../const";

export default {
  async loadSolutions(context, payload) {
    const response = await fetch(`${HOST}/api/solutions/`);
    const responseData = await response.json();
    if (!response.ok) {
      console.log("from error");

      const error = new Error(
        responseData.message || "Failed to fetch Solutions!"
      );
      throw error;
    }

    const solutions = [];
    for (const key in responseData) {
      const solution = {
        id: responseData[key].id,
        name: responseData[key].name,
        description: responseData[key].description,
        main_image: responseData[key].main_image,
        icon_1: responseData[key].icon_1,
        icon_2: responseData[key].icon_2,
        icon_3: responseData[key].icon_3,
        icon_4: responseData[key].icon_4,
      };
      solutions.push(solution);
    }
    context.commit("setSolutions", solutions);
  },
};
