<template>
    <div class="slider">
        <div class="back" @click="prevImg">
            <font-awesome-icon :icon="['fas', 'angle-left']" />
        </div>
        <div class="image">
            <img :src="images[sliderNo].photo" alt="" />
        </div>

        <div class="next" @click="nextImg">
            <font-awesome-icon :icon="['fas', 'angle-right']" />
        </div>
    </div>
</template>
    
<script >
export default {
    props: ['images'],
    data() {
        return {
            sliderNo: 0
        }
    },
    computed: {},
    methods: {
        prevImg() {
            if (this.sliderNo == 0) {
            } else {
                this.sliderNo = (this.sliderNo - 1)
            }
        },
        nextImg() {
            if (this.sliderNo == this.images.length - 1) {
            } else {
                this.sliderNo = (this.sliderNo + 1)
            }
        },
    },

};
</script>
<style lang="scss" scoped>
@import '../../scss/vars';

.slider {
    height: 100%;
    max-width: 600px;
    display: flex;
    align-items: center;
    margin: auto;
    // background-color: $alt_1_BG_color ;

    .back,
    .next {
        width: 10%;
        height: 100%;
        background-color: $alt_1_BG_color ;
        // opacity: 0.7;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
            opacity: 0.7;
        }

        svg {
            width: 25px;
            height: 25px;
            border-radius: 50%;
            border: 1px solid $alt_3_color;
            margin: auto;
            color: $alt_3_color;
        }
    }

    .back {
        border-radius: 30px 0 0 30px;

    }

    .next {
        border-radius: 0 30px 30px 0;
    }

    .image {
        width: 80%;
        height: 100%;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        background-color: $alt_1_BG_color;

        img {
            height: 100%;
            aspect-ratio: 1/1;
            object-fit: contain;

            @media (max-width: 767px) {
                width: 100%;
            }
        }
    }

}
</style>