<template>
  <div class="home">
    <slider :images="homeSlider"></slider>
  </div>
  <base-container>
    <section class="about">
      <div class="main-img">
        <img :src="aboutImg" alt="">
      </div>
      <div class="text">
        <h2 class="title">About us</h2>
        <p class="description">We are a group of people who are ambitious, experienced and hard-working. Our team is
          mainly
          focused on home & building automation since 2017, AL-FANAR CO. is an intelligent service
          provider in the Palestine. With years of experience in the intelligent industry, it is committed to
          the field of smart home, smart building, smart hotel, management parking solution and media
          facade lighting system. providing the market with comprehensive intelligent control system and
          integrated solutions, helping customers to achieve the comfortable, economical, and reduce
          energy consumption costs in their building.</p>

        <router-link to="/about"><span class="more">Read More</span></router-link>

      </div>
    </section>
  </base-container>
  <section class="profile">
    <base-container>
      <div class="profile-content">
        <div class="profile-logo">
          <div class="logo"><img src="../svg/N.svg" alt="Alfanar Logo"></div>
          <div class="description">Low voltage electrical suppliers & solutions company</div>
        </div>
        <div class="goto">
          <span>GO TO</span>
          <button>Company Profile</button>
        </div>
      </div>
    </base-container>
  </section>
  <base-container>
    <div class="completed-projects">
      <h1>We completed 1200+ projects yearly successfully and counting</h1>
      <div class="details">
        <div class="card">
          <div class="icon"><font-awesome-icon :icon="['fas', 'check']" /></div>
          <div class="text">
            <span class="number">100 +</span>
            <p class="description">Project Complete</p>
          </div>
        </div>
        <div class="card">
          <div class="icon"><font-awesome-icon :icon="['fas', 'clipboard-check']" /></div>
          <div class="text">
            <span class="number">100 +</span>
            <p class="description">Active Projects</p>
          </div>
        </div>
        <div class="card client-satissfied">
          <div class="icon"><font-awesome-icon :icon="['fas', 'person-circle-check']" /></div>
          <div class="text">
            <span class="number">90 +</span>
            <p class="description">Client Satissfied</p>
          </div>
        </div>
        <div class="card">
          <div class="icon"><font-awesome-icon :icon="['fas', 'user-group']" /></div>
          <div class="text">
            <span class="number">56 +</span>
            <p class="description">Country Available</p>
          </div>
        </div>
      </div>
    </div>
  </base-container>
  <section class="solution-section">
    <base-container>
      <h1>Solutions</h1>
      <p>leading the low voltage solution in Palestine</p>

      <div class="slider">
        <div class="back" @click="prevSolution()">
          <font-awesome-icon :icon="['fas', 'angle-left']" />
        </div>

        <div class="solutions">
          <ProductCard v-for="solution in willShowSolutions" :key="solution.id" :id="solution.id"
            :mainImg="solution.main_image" :title="solution.name" />
        </div>

        <div class="next" @click="nextSolution()">
          <font-awesome-icon :icon="['fas', 'angle-right']" />
        </div>


      </div>
    </base-container>
  </section>
  <base-container>
    <section class="works">
      <h1>Our Works</h1>
      <div class="brands">
        <div class="brand" v-for="brand in brands" :key="brand.id">
          <router-link :to="getBrand(brand.id)">
            <img :src="brand.logo" alt="Logo" />
          </router-link>
        </div>
      </div>
    </section>
  </base-container>
</template>

<script >
import s1 from '../assets/images/Untitled-2-01.jpg'
import s2 from '../assets/images/Untitled-2-02.jpg'
import s3 from '../assets/images/Untitled-2-03.jpg'
import s4 from '../assets/images/Untitled-2-04.jpg'
import s5 from '../assets/images/Untitled-2-05.jpg'
import abouImg from '../svg/about.svg'
import ProductCard from '@/components/brands/ProductCard.vue';

export default {
  components: {
    ProductCard,
  },
  data() {
    return {
      aboutImg: abouImg,
      homeSlider: [
        {
          "id": 1,
          "photo": s1,
          "description": 'peace of mind'
        }, {
          "id": 2,
          "photo": s2,
          "description": 'save your family'
        }, {
          "id": 3,
          "photo": s3,
          "description": 'peace of mind'
        }, {
          "id": 4,
          "photo": s4,
          "description": 'save your family'
        }, {
          "id": 5,
          "photo": s5,
          "description": null
        }, {
          "id": 3,
          "photo": s3,
          "description": 'peace of mind'
        },],
      allSolutions: [],
      willShowSolutions: [],
      sliderNo: 0,
    }
  },
  computed: {
    ShowSolutions() {
      const solutions = this.$store.getters['solutions/solutions'];
      this.allSolutions = solutions;
      if (solutions.length <= 3) {
        this.willShowSolutions = solutions;
      } else {
        this.willShowSolutions = solutions.slice(this.sliderNo, this.sliderNo + 3)
      }
    },
    brands() {
      return this.$store.getters["brands/brands"];
    },

  },
  created() {
    this.loadSolutions();
    this.loadBrands();

  },
  methods: {
    async loadSolutions() {
      try {
        await this.$store.dispatch('solutions/loadSolutions');
        this.ShowSolutions;
      } catch (error) {
        this.error = error.message || 'Something went wrong!';
      }
    },
    async loadBrands() {
      try {
        await this.$store.dispatch("brands/loadBrands", { brandId: "" });
      }
      catch (error) {
        this.error = error.message || "Something went wrong!";
      }
      this.isLoading = false;
    },
    getBrand(brandId) {
      return `/brands/${brandId}`
    },
    prevSolution() {
      if (this.sliderNo == 0) {
        console.log('could not decrease!!!')
      } else {
        this.sliderNo = (this.sliderNo - 1)
        this.willShowSolutions = [this.allSolutions[this.sliderNo], this.allSolutions[this.sliderNo + 1], this.allSolutions[this.sliderNo + 2]]
      }
    },
    nextSolution() {
      console.log(this.allSolutions)
      if (this.sliderNo == this.allSolutions.length - 3) {
        console.log('could not increase!!!')
      } else {
        this.sliderNo = (this.sliderNo + 1)
        this.willShowSolutions = [this.allSolutions[this.sliderNo], this.allSolutions[this.sliderNo + 1], this.allSolutions[this.sliderNo + 2]]
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../scss/vars';

.home {
  margin-bottom: 50px;
  width: 100%;
  height: 400px;
  overflow: hidden;
  border-radius: 0 0 15% 50%/0 0 30% 60%;

  @media (max-width: 767px) {
    border-radius: 0;
    height: 350px;
    overflow: visible;

  }
}

.about {
  display: flex;
  flex-direction: row-reverse;

  @media (max-width: 767px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .main-img {
    min-width: 25%;

    @media (max-width: 767px) {
      height: 200px;
      margin-top: -70px;
    }

    img {
      height: 100%;
      aspect-ratio: 1/1;
      object-fit: contain;
    }
  }

  .text {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    margin-bottom: 50px;
    padding: 20px;

    .title {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 20px;
    }

    .description {
      text-align: justify;
      line-height: 22px;
      color: #999;
    }

  }

}

.profile {
  background-image: url('../assets/images/profilebackground.jpg');
  background-size: cover;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    z-index: 1;

    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(90deg, rgba(243, 218, 106, 0.428) 0%, rgba(240, 241, 241, 0.192) 100%);

  }

  .profile-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 2;

    @media (max-width: 767px) {
      flex-direction: column;
    }

    .profile-logo {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @media (max-width: 767px) {
        width: 100%;

      }

      .logo {
        // width: 500px;
        height: 300px;
        overflow: hidden;

        img {
          height: 100%;
          aspect-ratio: 1/1;
          object-fit: contain;

        }
      }

      .description {
        margin-top: -100px;
        margin-bottom: 100px;
        color: $alt_2_BG_color;
        font-size: 25px;
        text-align: start;

        @media (max-width: 767px) {
          font-size: 18px;
          margin-bottom: 30px;
          text-align: center;
        }
      }
    }

    .goto {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;

      @media (max-width: 767px) {
        width: 100%;
      }

      span {
        color: $alt_2_BG_color;
        font-size: 45px;

        @media (max-width: 767px) {
          font-size: 35px;
        }
      }

      button {
        border: none;
        background-color: $alt_2_BG_color;
        color: $main_BG_color;
        border-radius: 50px;
        font-size: 40px;
        cursor: pointer;
        padding: 10px 25px 10px 25px;

        @media (max-width: 767px) {
          font-size: 30px;
          margin-bottom: 50px;
        }
      }
    }
  }
}

.completed-projects {
  margin: 50px auto 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  h1 {
    font-size: 30px;
    text-transform: capitalize;
    font-weight: normal;
    width: 60%;
    line-height: 40px;

    @media (max-width: 767px) {
      font-size: 20px;
      width: 100%;
      line-height: 25px;
    }
  }

  .details {
    margin: 50px auto;
    display: flex;
    gap: 20px;

    @media (max-width: 767px) {
      flex-direction: column;
      gap: 5px;
    }

    .card {
      background-color: $alt_2_BG_color;
      padding: 20px;
      border-radius: 50px;
      display: flex;
      gap: 5px;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      .icon {
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          width: 100%;
          height: 100%;
          font-weight: bold;
          color: $alt_color;
        }

      }

      .text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 6px;

        .number {
          color: $alt_color;
          font-size: 20px;
          font-weight: bolder;
        }

        .description {}
      }

      &:first-of-type {
        .icon {
          border-radius: 50%;
          border: 3px solid $alt_color;
        }
      }

      &.client-satissfied {
        .icon {
          border-radius: 50%;
          background-color: $alt_color;

          svg {
            color: $alt_2_BG_color;
          }
        }

      }

      &:last-of-type {
        .icon {
          border-radius: 50%;
          background-color: $alt_color;

          svg {
            color: $alt_2_BG_color;
          }
        }
      }

      &:hover {
        box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;

        .text {
          .description {
            color: $alt_color;

          }
        }
      }
    }


  }
}

.solution-section {
  background-image: url('../assets/images/Untitled-2-05.jpg');
  background-size: cover;
  position: relative;
padding-top: 50px;
 

  h1 {
    // margin-top: 100px;
    margin-bottom: 30px;
    font-size: 25px;
   text-transform: uppercase;
  }

  p {
    position: relative;
    z-index: 2;
    font-size: 20px;
  }

  .slider {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: relative;
    z-index: 2;

    .solutions {
      width: fit-content;
      margin-top: 50px;
      margin-bottom: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 15px;

      @media (max-width: 767px) {
        flex-direction: column;
      }

      // .cover {
      //   margin: auto;

      //   .layout {
      //     width: 250px;
      //     background-color: $alt_2_BG_color ;
      //     border-radius: 15px;
      //     padding: 20px;
      //     display: flex;
      //     flex-direction: column;
      //     justify-content: space-between;
      //     align-items: center;

      //     .main-img {
      //       width: 100%;
      //       height: 150px;

      //       @media (max-width: 767px) {
      //         height: 70px;
      //       }

      //       img {
      //         height: 100%;
      //         aspect-ratio: 1/1;
      //         object-fit: contain;
      //       }
      //     }



      //     .title {
      //       color: $main_color;
      //       letter-spacing: 0.5px;
      //       font-size: 15px;
      //       text-transform: uppercase;
      //       margin-bottom: 20px;

      //       @media (max-width: 767px) {
      //         font-size: 16px;
      //       }
      //     }

      //     &:hover {
      //       transform: scale(1.1);

      //       .title {
      //         font-size: 18px;
      //         font-weight: bold;

      //         @media (max-width: 767px) {
      //           font-size: 18px;
      //           font-weight: bold;
      //         }
      //       }
      //     }
      //   }
      // }


    }

    .back,
    .next {
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        border: 2px solid $alt_3_color;
        margin: auto;
        color: $alt_3_color;
        cursor: pointer;

        &:hover {
          transform: scale(1.1);
        }

      }
    }
  }


}

.more {
  font-size: smaller;
  height: 30px;
  border: none;
  background-color: $main_BG_color;
  color: $main_color;
  border-radius: 15px;
  cursor: pointer;
  padding: 5px 15px 5px 15px;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 14px, rgba(0, 0, 0, 0.09) 0px 5px 8px, rgba(239, 219, 142, 0.4745098039) 0px 10px 15px;
}

.works {
  margin: 50px auto 150px;

  h1 {
    margin-bottom: 20px;
  }

  .brands {
    width: fit-content;
    padding: 15px 50px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
    background-color: $alt_1_BG_color;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
    border-radius: 20px;

    @media (max-width: 767px) {
      gap: 10px;
      padding: 15px 15px;

    }

    .brand {
      display: flex;
      justify-content: center;
      align-items: center;
      // border: 1px solid;
      max-width: 130px;
      overflow: hidden;
      height: 80px;

      &:hover {
        transform: scale(1.1);
      }

      img {
        width: 100%;
        aspect-ratio: 1/1;
        object-fit: contain;
      }
    }
  }
}
</style>