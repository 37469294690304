<template>
    <footer>
        <base-container>
            <Subscripton />
            <ul class="links">
                <li>
                    <ul>
                        <li>About Us</li>
                        <li>About Alfanar</li>
                        <li>Our Vision</li>
                        <li>Our Quality Policy</li>
                        <li>Our Mission</li>
                    </ul>
                </li>
                <li>
                    <ul>
                        <li>About Us</li>
                        <li>What is Alfanar Company</li>
                    </ul>
                </li>
                <li>
                    <ul>
                        <li>Privacy Policy</li>
                        <li>Cookie Policy</li>
                        <li>Our Partners</li>
                        <li>Solutions</li>
                    </ul>
                </li>
                <li>
                    <ul>
                        <li>Contact Information</li>
                        <li>Alfanar@info.ps</li>
                        <li>Ramallah / Betunia</li>
                        <li>+972 382 832 2832</li>
                    </ul>
                </li>
            </ul>
            <div class="support-right">
                <div class="support">
                    <p>Lokking for support? visit help and support</p>
                    <div class="img">
                        <img src="../svg/N.svg" alt="">
                    </div>
                </div>
                <div class="right">
                    <p>&copy; All rights reserved to Al fanar 2022</p>
                    <ul>
                        <li><a href="https://www.facebook.com/" target="_blank"><font-awesome-icon
                                    :icon="['fab', 'instagram']" /></a></li>

                        <li><a href="https://twitter.com/" target="_blank"><font-awesome-icon
                                    :icon="['fab', 'twitter']" /></a></li>

                        <li>Usage Policy</li>
                        <li>Privacy Policy</li>
                        <li>Contact Us</li>
                    </ul>
                </div>
            </div>
        </base-container>
    </footer>
</template>
  
<script >
import Subscripton from './bases/Subscripton.vue';
export default {
    components: {
        Subscripton
    },
    data() {
        return {
            path: '',
        }
    },
    // created() {
    //     this.path=this.$route.path
    //     console.log('path is : '+this.$route.path)
    // },
}
</script>
  
<style scoped lang="scss">
@import '../scss/vars';

footer {
    background-color: $main_BG_color;
    padding-top: 150px;
    position: relative;
    padding-bottom: 20px;

    .links {
        display: flex;
        // flex-wrap: wrap;
        justify-content: space-around;
        align-items: flex-start;

        @media (max-width: 767px) {
            flex-direction: column;
            align-items: center;
            gap: 20px;
        }

        li {
            ul {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 15px;

                @media (max-width: 767px) {
                    align-items: center;
                    gap: 10px;

                }

                li {
                    &:first-child {
                        font-weight: bold;
                        font-size: 20px;
                        color: $main_color;

                        // @media (max-width: 767px) {
                        //     font-size: 15px;
                        // }
                    }

                    font-size: 12px;
                    color: $alt_3_color;

                    // @media (max-width: 767px) {
                    //     font-size: 10px;
                    // }
                }
            }
        }
    }

    .support-right {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        border-top: 1px solid $alt_3_color;
        padding: 20px 20px 0 0;
        margin-top: 20px;

        @media (max-width: 767px) {
            flex-direction: column;
            align-items: center;
            gap: 10px;

        }

        .support {
            display: flex;
            flex-direction: column;
            // justify-content: space-between;
            align-items: flex-start;

            @media (max-width: 767px) {
                align-items: center;
            }

            p {
                color: $alt_3_color;
            }

            .img {
                width: 150px;
                height: 75px;
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: 100%;
                }
            }

        }

        .right {
            display: flex;
            flex-direction: column;
            // justify-content: space-between;
            align-items: flex-end;
            gap: 10px;

            ul {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 30px;

                @media (max-width: 767px) {
                    gap: 10px;
                }

                li {
                    color: $alt_3_color;

                    @media (max-width: 767px) {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}
</style>
  