<template>
    <div class="solution">
        <router-link :to="getBrand(id)">
            <div class="main-img">
                <img :src="mainImg" alt="" />
            </div>
        </router-link>
        <h2 class="title">
            {{ title }}
        </h2>

    </div>
</template>
    
<script >
export default {
    props: ['id', 'mainImg', 'title', 'description', 'logos'],
    methods: {
        getBrand(solutionId) {
            return `/products/${solutionId}`
        },
    },
};
</script>
<style lang="scss" scoped>
@import '../../scss/vars';

.solution {
    width: 250px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 15px;
    background-color: white;
    padding: 15px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    @media (max-width: 767px) {}

    &:hover {}

    .main-img {
        width: 200px;
        aspect-ratio: 1 / 1;
        // border-radius: 50%;
        overflow: hidden;
        // box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
            // background-color: $main_BG_color1;
            border: 1px solid $main_BG_color1;

        }

        @media (max-width: 767px) {
            // width: 50%;
        }

        img {
            width: 100%;
            aspect-ratio: 1/1;
            object-fit: contain;
        }
    }

    .title {
        width: 100%;
        font-weight: bold;
        font-size: 18px;
        line-height: 45px;
        text-transform: capitalize;
        margin-top: 10px;
        color: black;
        border: none;
        border-radius: 25px;
        background-color: $main_BG_color;

        @media (max-width: 767px) {
            font-size: 20px;

        }
    }

}
</style>