<template>
  <base-dialog :show="isLoading" title="Load Brands...">
    <base-spinner></base-spinner>
  </base-dialog>

  <div class="header-img">
    <the-header />
  </div>
  <BaseContainer>
    <div v-if="brands.length === 0" class="comming-soon">
      <h1>new brands will coming soon<span class="dot">.</span></h1>
    </div>
    <div v-else class="brands">
      <div class="card" v-for="brand in brands" :key="brand.id">
        <div class="card-img">
          <img :src="brand.logo" alt="Logo" />
        </div>
        <div class="card-info">
          <router-link :to="getBrand(brand.id)" class="img-container">
            <p class="text-title"> {{ brand.name }}
            </p>
          </router-link>
        </div>
      </div>
    </div>

    <div class="contact">
      <h3>Contact our sales team</h3>
      <router-link to="/contactus">Lets explore how we can help you
        <font-awesome-icon :icon="['fas', 'circle-chevron-right']" />
      </router-link>
    </div>
  </BaseContainer>
</template>
  
<script >
import BaseContainer from '@/assets/bases/BaseContainer.vue';

export default {
  components: { BaseContainer },
  props: ['solutionId'],
  data() {
    return {
      // showDialog: false,
      // ErrorMsg: '',
      isLoading: false,
    }
  },
  computed: {
    brands() {
      return this.$store.getters["brands/brands"];
    },

  },
  created() {
    this.loadBrands();
  },
  methods: {
    getBrand(brandId) {
      var currentUrl = window.location.pathname;
      return `${currentUrl}/${brandId}`
    },
    async loadBrands() {
      this.isLoading = true;
      console.log("load Brands for soluution ..." + this.solutionId + " /BrandsVue.vue file");

      try {
        await this.$store.dispatch("brands/loadBrands", { solutionId: this.solutionId });
      }
      catch (error) {
        this.error = error.message || "Something went wrong!";
      }
      this.isLoading = false;
    },
  },

}; 
</script>

<style lang="scss" scoped>
@import '../scss/vars';

.header-img {
  background-image: url('../assets/images/productsBG.jpg');
  background-size: cover;
  height: 400px;
  overflow: hidden;
  border-radius: 0 0 15% 50%/0 0 30% 60%;

  @media (max-width: 767px) {
    border-radius: 0;
    overflow: visible;
    height: 150px;
  }

  position: relative;

  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(82, 75, 46, 0.507) 0%, rgba(240, 241, 241, 0.192) 100%);

  }
}

.comming-soon {
  margin-top: 50px;
  margin-bottom: 50px;
  width: 100%;

  h1 {
    font-size: 50px;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 0;
    line-height: 1;
    font-weight: 700;
    text-transform: capitalize;
  }

  .dot {
    color: #4FEBFE;
  }
}

.brands {
  width: 100%;
  margin: 50px auto 50px;
  // display: grid;
  // grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));

  display: flex;
  flex-wrap: wrap;

  justify-content: center;
  align-items: center;
  gap: 40px;

  .card {
    width: 190px;
    height: 254px;
    padding: .8em;
    background: #f5f5f5;
    position: relative;
    overflow: visible;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .card-img {
      // background-color: #ffcaa6;
      height: 70%;
      width: 100%;
      border-radius: .5rem;
      transition: .3s ease;

      // .img-container {
      //   width: 100%;
      //   aspect-ratio: 1/1;
      //   object-fit: contain;

      img {
        width: 100%;
        aspect-ratio: 1/1;
        object-fit: contain;
      }

      // }

    }

    .card-img:hover {
      // transform: translateY(-25%);
      scale: 1.1;
      // box-shadow: rgba(226, 196, 63, 0.25) 0px 13px 47px -5px, rgba(180, 71, 71, 0.3) 0px 8px 16px -8px;
    }

    .card-info {
      padding-top: 10%;
      width: 100%;

      .text-title {
        width: 100%;
        background-color: $main_BG_color;
        font-weight: 900;
        font-size: 1.2em;
        line-height: 1.5;
      }
    }
  }
}

.contact {
  margin-bottom: 100px;

  h3 {
    font-size: 20px;
    font-weight: bold;
    line-height: 26px;
  }

  a {
    font-size: 16px;
    line-height: 20px;
    color: $main_color;

    svg {
      color: $alt_color;
      font-size: 20px;

    }

  }
}
</style>