import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeVue.vue'
import AboutView from '../views/AboutVue.vue'
import NewsView from '../views/NewsVue.vue'

import BrandsView from '../views/BrandsVue.vue'
import Categories from '../components/brands/Categories.vue'
import ProductDetails from '../components/brands/ProductDetails.vue'

import ProjectsView from '../views/ProjectsVue.vue'
import ReferenceProject from '../components/projects/ReferenceProject.vue'

import SolutionsVue from '../views/SolutionsVue.vue'
import ProductsVue from '../views/ProductsVue.vue'

import ContactUsVue from '../views/ContactUsVue.vue'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/news',
    name: 'news',
    component: NewsView
  }, {
    path: '/solutions',
    name: 'solutions',
    component: SolutionsVue
  },
  {
    path: '/products',
    name: 'products',
    component: ProductsVue
  },
  {
    path: '/products/:solutionId',
    name: 'solutionBbrands',
    component: BrandsView,
    props: true,
  },
  {
    path: '/products/:solutionId/:brandId',
    component: Categories,
    props: true,

  },
  {
    path: '/products/:solutionId/:brandId/:productId',
    component: ProductDetails,
    props: true,

  },
  {
    path: '/projects',
    name: 'projects',
    component: ProjectsView
  },
  {
    path: '/projects/:projectId',
    component: ReferenceProject,
    props: true,

  },
  {
    path: '/contactus',
    name: 'contactus',
    component: ContactUsVue
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
