import { createStore } from "vuex";

import solutionsModule from "./modules/solutions/index.js";
import projectsModule from "./modules/projects/index.js";
import brandsModule from "./modules/brands/index.js";

const store = createStore({
  modules: {
    solutions: solutionsModule,
    projects: projectsModule,
    brands: brandsModule,
  },
});

export default store;
