<template>
  <div class="layout">
    <div class="header-img">
      <the-header />
    </div>
  </div>
  <base-container>
    <div class="projects">
      <RefProjCover v-for="project in allprojects" :key="project.id" :project="project" :id="project.id"
        :mainImg="project.preview_photo" :title="project.project_name" :description="project.project_description" />

    </div>
  </base-container>
</template>
  
<script >
import RefProjCover from '@/components//projects/RefProjCover.vue';
export default {
  components: {
    RefProjCover
  },
  computed: {
    allprojects() {
      const projects = this.$store.getters['projects/projects'];

      return projects
    },
  },
  created() {
    this.loadProjects();
  },
  methods: {
    async loadProjects(refresh = false) {
      this.isLoading = true;
      try {
        await this.$store.dispatch('projects/loadProjects', { projectId: '' });
      } catch (error) {
        this.error = error.message || 'Something went wrong!';
      }
      this.isLoading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../scss/vars';

.layout {
  .header-img {
    background-image: url('../assets/images/productsBG.jpg');
    background-size: cover;
    margin-bottom: 50px;
    width: 100%;
    height: 400px;
    overflow: hidden;
    border-radius: 0 0 15% 50%/0 0 30% 60%;

    @media (max-width: 767px) {
      border-radius: 0;
      height: 150px;
      overflow: visible;

    }

    position: relative;

    &::before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(90deg, rgba(82, 75, 46, 0.507) 0%, rgba(240, 241, 241, 0.192) 100%);
    }
  }
}

.projects {
  margin: 50px auto 150px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 40px;
}
</style>