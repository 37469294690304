const HOST = 'https://alfanar-be-production.up.railway.app'
// const HOST = "https://api.alfanar.ps";
export default HOST;

/*
 * api/market/categories/
 * api/market/categories/id
 * api/market/brands/
 * api/market/brands/id
 * api/market/products/
 * api/market/products/1/
 * 
 * /solutions..... done
 * /products/knx =: brands in this solution.... done
 * /products/knx/inteera =: categories
 * /products/knx/inteera/category
 * /products/knx/inteera/category/productId
*/

/*
 * api/solutions => all solutions
 *
 * api/solution/:solutionName => brands for solutionName
 *                 EX: api/solution/knx => brands for knx
 *
 * api/brands/:solutionName => brands for solutionName
 *                  EX: api/brands/knx => brands for knx
 *
 * api/products/:brandName => Categories for brandName
 *                  EX: api/products/inteera => brands for inteera
 *
 * api/products/:brandName/:categoryName => products for categoryName
 *                  EX: api/products/inteera/IP-INTERCOM => products for IP-INTERCOM
 */
