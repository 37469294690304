
// import TheHeader from './assets/TheHeader.vue';
import TheFooter from './assets/TheFooter.vue';
export default {
  components: {
    // TheHeader,
    TheFooter,
  },
  methods: {
  },
}
