<template>
    <div class="header-img">
        <the-header />
    </div>
    <base-container>
        <form @submit.prevent="sendForm">
            <!-- <div class="form-part">
                <div class="control">
                    <label for="firstName">First Name</label>
                    <input :class="{ invalid: !firstName.isValid }" type="text" id="firstName"
                        v-model.trim="firstName.val" />
                    <p v-if="!firstName.isValid">Please type your name</p>
                </div>
                <div class="control">
                    <label for="lastName">LastName</label>
                    <input :class="{ invalid: !lastName.isValid }" type="text" id="lastName" v-model.trim="lastName.val" />
                    <p v-if="!lastName.isValid">Please type your name</p>
                </div>
                 <div class="control">
                    <label for="email">E-mail</label>
                    <input :class="{ invalid: !email.isValid }" type="email" id="email" v-model.trim="email.val" />
                    <p v-if="!email.isValid">Please type your email</p> 
                </div> 
                  <div class="control">
                    <label for="companyName">Company Name</label>
                    <input :class="{ invalid: !companyName.isValid }" type="text" id="companyName"
                        v-model.trim="companyName.val" />
                     <p v-if="!companyName.isValid">Please type your Company Name</p> 
                </div> 
            </div>
            <div class="form-part">

                <div class="control">
                    <label for="description">Anything else</label>
                    <textarea :class="{ invalid: !description.isValid }" id="description"
                        v-model.trim="description.val"></textarea>
                    <p v-if="!description.isValid">Please type your message</p>

                </div>
                <div class="accept">
                    <div class="checkbox-wrapper">
                        <input type="checkbox" checked="" />
                        <svg viewBox="0 0 35.6 35.6">
                            <circle r="17.8" cy="17.8" cx="17.8" class="background"></circle>
                            <circle r="14.37" cy="17.8" cx="17.8" class="stroke"></circle>
                            <polyline points="11.78 18.12 15.55 22.23 25.17 12.87" class="check"></polyline>
                        </svg>
                    </div>
                    <label for="accept">By submitting this form, I aknowledge receipt of the Alfanar Privacy policy</label>
                </div>
                <div class="control">
                    <button type="submit">Send Email</button>
                </div>
                <p v-if="sentSucces" class="sent">Your message sent succesfuly</p>

            </div> -->

            <div class="form-container">
                <div class="form">
                    <span class="heading">Get in touch</span>
                    <input placeholder="First Name" class="input" :class="{ invalid: !firstName.isValid }" type="text"
                        id="firstName" v-model.trim="firstName.val" />
                    <input placeholder="Last Name" class="input" :class="{ invalid: !lastName.isValid }" type="text"
                        id="lastName" v-model.trim="lastName.val" />

                    <input placeholder="Email" class="input" :class="{ invalid: !email.isValid }" type="email" id="email"
                        v-model.trim="email.val">
                    <input placeholder="Company Name" class="input" :class="{ invalid: !companyName.isValid }" type="text"
                        id="companyName" v-model.trim="companyName.val" />

                    <textarea placeholder="Write your message" rows="10" cols="30" class="textarea"
                        :class="{ invalid: !description.isValid }" id="description"
                        v-model.trim="description.val"></textarea>
                    <div class="accept">
                        <div class="checkbox-wrapper">
                            <input type="checkbox" checked="" />
                            <svg viewBox="0 0 35.6 35.6">
                                <circle r="17.8" cy="17.8" cx="17.8" class="background"></circle>
                                <circle r="14.37" cy="17.8" cx="17.8" class="stroke"></circle>
                                <polyline points="11.78 18.12 15.55 22.23 25.17 12.87" class="check"></polyline>
                            </svg>
                        </div>
                        <label for="accept">By submitting this form, I aknowledge receipt of the Alfanar Privacy
                            policy</label>
                    </div>
                    <div class="button-container">
                        <button type="submit" class="send-button">Send</button>
                        <div class="reset-button-container">
                            <div id="reset-btn" class="reset-button" @click="clearData">Reset</div>
                        </div>
                    </div>

                    <p v-if="sentSucces" class="sent">Your message sent succesfuly</p>
                </div>
            </div>
        </form>
    </base-container>
</template>
  
<script >
export default {
    data() {
        return {

            formIsValid: true,
            firstName: {
                val: '',
                isValid: true,
            },
            lastName: {
                val: '',
                isValid: true,
            },
            email: {
                val: '',
                isValid: true,
            },
            companyName: {
                val: '',
                isValid: true,
            },
            // phone: {
            //     val: null,
            //     isValid: true,
            // },
            // country: {
            //     val: '',
            //     isValid: true,
            // },
            description: {
                val: '',
                isValid: true,
            },
            // budget: '',
            // lunchDate: '',
            sentSucces: false,
        }
    },

    computed: {
    },
    methods: {
        isValid() {
            this.formIsValid = true
            if (this.firstName.val === '') {
                this.firstName.isValid = false
                this.formIsValid = false
            } else {
                this.firstName.isValid = true
            }
            if (this.lastName.val === '') {
                this.lastName.isValid = false
                this.formIsValid = false
            } else {
                this.lastName.isValid = true
            }
            if (this.email.val === '' || !this.email.val.includes('@')) {
                this.email.isValid = false
                this.formIsValid = false
            } else {
                this.email.isValid = true
            }
            if (this.companyName.val === '') {
                this.companyName.isValid = false
                this.formIsValid = false
            } else {
                this.companyName.isValid = true
            }
            // if (this.phone.val === null) {
            //     this.phone.isValid = false
            //     this.formIsValid = false
            // } else {
            //     this.phone.isValid = true
            // }
            // if (this.country.val === '') {
            //     this.country.isValid = false
            //     this.formIsValid = false
            // } else {
            //     this.country.isValid = true
            // }
            if (this.description.val === '') {
                this.description.isValid = false
                this.formIsValid = false
            } else {
                this.description.isValid = true
            }
        },
        clearData() {
            this.firstName.val = ''
            this.lastName.val = ''
            this.email.val = ''
            this.companyName.val = ''

            // this.phone.val = null
            // this.country.val = ''
            this.description.val = ''

            this.firstName.isValid = true
            this.lastName.isValid = true
            this.email.isValid = true
            this.companyName.isValid = true
            // this.phone.isValid = true
            // this.country.isValid = true
            this.description.isValid = true
        },
        sendForm() {
            this.isValid()
            if (this.formIsValid) {
                this.send()
            }
        },
        async send(context, projectId) {
            const response = await fetch(`http://alfanar-be-production.up.railway.app/api/contact/`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json', // Make sure to set the correct content type
                    },

                    body: JSON.stringify({
                        first_name: this.firstName.val,
                        last_name: this.lastName.val,
                        email: this.email.val,
                        company_name: this.companyName.val,
                        message: this.description.val
                    }),
                });

            const responseData = await response.json();


            if (!response.ok) {
                const error = new Error(
                    responseData.message || "Failed to send request!"
                );
                throw error;
            }

            this.clearData()
            this.sentSucces = true
            setTimeout(() => (this.sentSucces = false), 5000)


        },
    },
}
</script>
 
<style scoped lang="scss">
@import '../scss/vars';

.header-img {
    background-image: url('../assets/images/contactusBG.jpg');
    background-size: cover;
    height: 400px;
    overflow: hidden;
    border-radius: 0 0 15% 50%/0 0 30% 60%;

    @media (max-width: 767px) {
        border-radius: 0;
        height: 150px;
        overflow: visible;

    }

    position: relative;

    &::before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, rgba(82, 75, 46, 0.507) 0%, rgba(240, 241, 241, 0.192) 100%);

    }
}

form {
    margin: 50px auto 100px;
    width: 80%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;

    @media (max-width: 767px) {
        width: 80%;
        flex-direction: column;
    }

    .form-part {
        width: 50%;

        @media (max-width: 767px) {
            width: 100%;
        }

        .name {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5px;

        }

        .control {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            margin: 6px auto;

            // @media (max-width: 767px) {
            //     margin: 5px auto;
            // }

            label {
                width: fit-content;
                margin-bottom: 6px;
                padding-left: 15px;

                // @media (max-width: 767px) {
                //     margin-bottom: 6px;
                //     font-size: 14px;
                // }
            }

            input {
                border: 1px solid $main_color;
                // border-bottom: 1px solid $main_color;
                height: 40px;
                background-color: transparent;
                caret-color: black;
                outline: none;
                border-radius: 10px;
                caret-color: $main_color;
                padding-left: 15px;
                transition: 0.5s;
                text-align: left;
                // @media (max-width: 767px) {
                //     height: 30px;
                //     font-size: 14px;
                // }
            }

            .invalid {
                border: 1px solid red;
            }

            textarea {
                border: 1px solid $main_color;
                background-color: transparent;
                caret-color: $main_color;
                outline: none;
                border-radius: 10px;
                padding-left: 15px;
                transition: 0.5s;
                height: 155px;
                text-align: left;

            }

            // input:hover,
            // textarea:hover,
            // select:hover {
            //     transform: scale(1.055) translateY(-4px);
            // }

            button {
                width: 100%;
                border-radius: 30px;
                border: none;
                height: 55px;
                background-color: $alt_3_BG_color;
                transition: 0.5s;
                color: $alt_2_color;
                margin-top: 10px;
            }

            a:hover,
            button:hover {
                transform: scale(0.85);
                background-color: $main_BG_color;
                color: white;

            }

            p {
                color: red;
            }

            // p {
            // @media (max-width: 767px) {
            //     height: 15px;
            //     font-size: 14px;
            // }
            // }
        }

        .accept {

            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5px;

            .checkbox-wrapper {
                position: relative;
                display: inline-block;
                width: 40px;
                height: 40px;

                &:hover {
                    .check {
                        stroke-dashoffset: 0;
                    }
                }

                .background {
                    fill: lime;
                    transition: ease all 0.6s;
                    -webkit-transition: ease all 0.6s;
                }

                .stroke {
                    fill: none;
                    stroke: lime;
                    stroke-miterlimit: 10;
                    stroke-width: 2px;
                    stroke-dashoffset: 100;
                    stroke-dasharray: 100;
                    transition: ease all 0.6s;
                    -webkit-transition: ease all 0.6s;
                }

                .check {
                    fill: none;
                    stroke: white;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                    stroke-width: 2px;
                    stroke-dashoffset: 22;
                    stroke-dasharray: 22;
                    transition: ease all 0.6s;
                    -webkit-transition: ease all 0.6s;
                }

                input[type="checkbox"] {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    margin: 0;
                    opacity: 0;
                    -appearance: none;
                    -webkit-appearance: none;

                    &:hover {
                        cursor: pointer;
                    }

                    &:checked+svg {
                        .background {
                            fill: black;
                        }

                        .stroke {
                            stroke-dashoffset: 0;
                        }

                        .check {
                            stroke-dashoffset: 0;
                        }
                    }
                }

            }

            label {
                font-size: 12px;
            }
        }

        .sent {
            text-transform: uppercase;
            color: green;
        }
    }



    .form-container {
        max-width: 700px;
        margin: 30px;
        background-color: #001925;
        padding: 30px;
        border-left: 5px solid $main_BG_color;
        clip-path: polygon(0 0, 100% 0, 100% calc(100% - 20px), calc(100% - 20px) 100%, 0 100%);

        .form {
            .input {
                color: #87a4b6;
                width: 100%;
                background-color: #002733;
                border: none;
                outline: none;
                padding: 10px;
                margin-bottom: 20px;
                font-weight: bold;
                transition: all 0.2s ease-in-out;
                border-left: 1px solid transparent;

                &:focus {
                    border-left: 5px solid $main_BG_color;
                }
            }

            .textarea {
                width: 100%;
                padding: 10px;
                border: none;
                outline: none;
                background-color: #013747;
                color: $main_BG_color;
                font-weight: bold;
                resize: none;
                max-height: 150px;
                margin-bottom: 20px;
                border-left: 1px solid transparent;
                transition: all 0.2s ease-in-out;

                &:focus {
                    border-left: 5px solid $main_BG_color;
                }
            }

            .invalid {
                border: 1px solid red;
            }

            .button-container {
                display: flex;
                gap: 10px;

                .send-button {
                    flex-basis: 70%;
                    background: $main_BG_color;
                    padding: 10px;
                    color: #001925;
                    text-align: center;
                    font-weight: bold;
                    border: 1px solid transparent;
                    transition: all 0.2s ease-in-out;

                    &:hover {
                        background: transparent;
                        border: 1px solid $main_BG_color;
                        color: $main_BG_color;
                    }
                }

                .reset-button-container {
                    filter: drop-shadow(1px 1px 0px $main_BG_color);
                    flex-basis: 30%;

                    .reset-button {
                        position: relative;
                        text-align: center;
                        padding: 10px;
                        color: $main_BG_color;
                        font-weight: bold;
                        background: #001925;
                        clip-path: polygon(0 0, 100% 0, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0 100%);
                        transition: all 0.2s ease-in-out;

                        &:hover {
                            background: $main_BG_color;
                            color: #001925;
                        }
                    }
                }
            }

            .accept {

                display: flex;
                justify-content: center;
                align-items: center;
                gap: 5px;

                .checkbox-wrapper {
                    position: relative;
                    display: inline-block;
                    width: 40px;
                    height: 40px;

                    &:hover {
                        .check {
                            stroke-dashoffset: 0;
                        }
                    }

                    .background {
                        fill: lime;
                        transition: ease all 0.6s;
                        -webkit-transition: ease all 0.6s;
                    }

                    .stroke {
                        fill: none;
                        stroke: lime;
                        stroke-miterlimit: 10;
                        stroke-width: 2px;
                        stroke-dashoffset: 100;
                        stroke-dasharray: 100;
                        transition: ease all 0.6s;
                        -webkit-transition: ease all 0.6s;
                    }

                    .check {
                        fill: none;
                        stroke: white;
                        stroke-linecap: round;
                        stroke-linejoin: round;
                        stroke-width: 2px;
                        stroke-dashoffset: 22;
                        stroke-dasharray: 22;
                        transition: ease all 0.6s;
                        -webkit-transition: ease all 0.6s;
                    }

                    input[type="checkbox"] {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        left: 0;
                        top: 0;
                        margin: 0;
                        opacity: 0;
                        -appearance: none;
                        -webkit-appearance: none;

                        &:hover {
                            cursor: pointer;
                        }

                        &:checked+svg {
                            .background {
                                fill: black;
                            }

                            .stroke {
                                stroke-dashoffset: 0;
                            }

                            .check {
                                stroke-dashoffset: 0;
                            }
                        }
                    }

                }

                label {
                    font-size: 12px;
                    color: white;
                }
            }

            .sent {
                text-transform: uppercase;
                color: green;
            }
        }

    }

    .heading {
        display: block;
        color: white;
        font-size: 1.5rem;
        font-weight: 800;
        margin-bottom: 20px;
    }

}
</style>
  