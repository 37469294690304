import HOST from "../const";

export default {
  async loadBrand(context, payload) {
    console.log("loading Brand with ID: " + payload.brandId);

    const response = await fetch(
      `${HOST}/api/market/brands/${payload.brandId}`
    );

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(
        responseData.message || "Failed to fetch Brands!"
      );
      throw error;
    }

    const brand = {
      id: responseData.id,
      name: responseData.name,
      description: responseData.description,
      logo: responseData.logo,
      solution: responseData.solution,
    };

    context.commit("setBrand", brand);
    // context.commit("setFetchTimestamp");
  },

  async loadBrands(context, payload) {
    console.log("load Brands for soluution ..." + payload.solutionId);

    const response = await fetch(`${HOST}/api/market/brands/`);

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(
        responseData.message || "Failed to fetch Brands!"
      );
      throw error;
    }

    const brands = [];
    for (const key in responseData) {
      const brand = {
        id: responseData[key].id,
        name: responseData[key].name,
        description: responseData[key].description,
        logo: responseData[key].logo,
        solution: responseData[key].solution,
      };
      if (brand.solution == payload.solutionId) {
        brands.push(brand);
        console.log("New Brand added..." + brand.name);
      }
    }
    context.commit("setBrands", brands);
    // context.commit("setFetchTimestamp");
  },

  async loadCategories(context, payload) {
    const response = await fetch(`${HOST}/api/market/categories/`);

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(
        responseData.message || "Failed to fetch categories!"
      );
      throw error;
    }

    const categories = [];
    for (const key in responseData) {
      const category = {
        id: responseData[key].id,
        name: responseData[key].name,
        brand: responseData[key].brand,
      };

      if (category.brand == payload.brandId) {
        categories.push(category);
        console.log("New category added..." + category.name);
      }
    }
    context.commit("setCategories", categories);
  },

  async loadProducts(context, payload) {
    const response = await fetch(
      `${HOST}/api/market/products/?category_id=${payload.categoryId}`
    );

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(
        responseData.message || "Failed to fetch products!"
      );
      throw error;
    }

    const products = [];
    for (const key in responseData) {
      const product = {
        id: responseData[key].id,
        product_photos: responseData[key].product_photos,
        features: responseData[key].features,
        name: responseData[key].name,
        short_description: responseData[key].short_description,
        description: responseData[key].description,
        data_sheet: responseData[key].data_sheet,
        category: responseData[key].category,
        brand: responseData[key].brand,
      };

      products.push(product);
    }
    context.commit("setProducts", products);
  },
  async loadProductDetails(context, payload) {
    console.log("product details 2 ...");
    const response = await fetch(
      `${HOST}/api/market/products/${payload.productId}`
    );

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(
        responseData.message || "Failed to fetch products!"
      );
      throw error;
    }
    // console.log("product details 3 ...");
    const product = {
      id: responseData.id,
      product_photos: responseData.product_photos,
      features: responseData.features,
      name: responseData.name,
      short_description: responseData.short_description,
      description: responseData.description,
      data_sheet: responseData.data_sheet,
      category: responseData.category,
      brand: responseData.brand,
    };
    // console.log("short_description..." + product.short_description);

    context.commit("setProduct", product);
    // console.log("product details done...");
  },
  resetProducts(context) {
    context.commit("setProducts", {});
  },
};
