export default {
  setBrand(state, payload) {
    state.brand = payload;
  },
  setBrands(state, payload) {
    state.brands = payload;
  },
  setCategories(state, payload) {
    state.categories = payload;
  },

  setProducts(state, payload) {
    state.products = payload;
  },
  setProduct(state, payload) {
    state.product = payload;
  },
};
