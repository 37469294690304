<template>
    <div class="header-img">
        <the-header />
    </div>
    <base-container>
        <div class="projest-details">
            <div class="main-img">
                <img :src="mainImg" alt="">
                <div class="location">
                    <font-awesome-icon :icon="['fas', 'location-dot']" />
                    <span>{{ location }}</span>
                </div>
            </div>
            <div class="text">
                <h2 class="title">{{ title }}</h2>
                <div class="date">
                    <!-- <span class="status" v-if="status == 'Completed'">{{ status }} </span> -->
                    <span class="delivary"> {{ startDate }} - {{ endDate }}</span>
                </div>
                <p class="description">{{ description }}</p>
            </div>
        </div>
        <div class="slider-container" v-if="images.length">
            <ProjectImagesSlider :images="images" />
        </div>
    </base-container>
</template>
    
<script >

import ProjectImagesSlider from './ProjectImagesSlider.vue'
export default {
    components: {
        ProjectImagesSlider,
    },
    props: ['projectId'],
    data() {
        return {
            mainImg: '',
            title: '',
            description: '',
            images: [],
            location: '',
            status: '',
            startDate: '',
            endDate: '',
        }
    },
    computed: {
        theProject() {
            const project = this.$store.getters['projects/project'];
            // console.log('this.project : ', project)
            this.mainImg = project.preview_photo;
            this.title = project.project_name;
            this.description = project.project_description;
            this.images = project.project_photos;
            this.location = project.location;
            this.status = project.status;
            this.startDate = project.startDate;
            this.endDate = project.endDate;
        },
    },
    created() {
        // this.$store.dispatch('projects/resetProject', {});
        this.loadProject();
    },
    methods: {
        async loadProject() {
            try {
                await this.$store.dispatch('projects/loadProject', { projectId: this.projectId });
                this.theProject();
            } catch (error) {
                this.error = error.message || 'Something went wrong!';
            }
        },
    },

};
</script>
<style lang="scss" scoped>
@import '../../scss/vars';

.header-img {
    background-image: url('../../assets/images/Untitled-2-01.jpg');
    background-size: cover;
    height: 400px;
    overflow: hidden;
    border-radius: 0 0 15% 50%/0 0 30% 60%;

    @media (max-width: 767px) {
        border-radius: 0;
        overflow: visible;
        height: 150px;
    }

    position: relative;

    &::before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, rgba(82, 75, 46, 0.507) 0%, rgba(240, 241, 241, 0.192) 100%);

    }
}

.projest-details {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin: 100px auto 50px;

    .main-img {
        width: 25%;
        // border: 1px solid;
        padding: 20px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;

        img {
            width: 100%;
        }

        .location {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5px;

            @media (max-width: 767px) {
                flex-direction: column;
            }

            svg {
                color: $alt_color;
                font-size: 25;
            }

            span {
                text-transform: uppercase;
                font-size: 15px;

                @media (max-width: 767px) {
                    font-size: 12px;

                }
            }
        }
    }

    .text {
        width: 75%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        // border: 1px solid;

        .title {
            font-weight: bold;
            font-size: 25px;
            text-transform: capitalize;
            margin-bottom: 10px;
        }

        .date {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            @media (max-width: 767px) {
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }

            margin-bottom: 20px;

            .status {
                color: $main_BG_color;
            }

            .delivary {
                color: $main_BG_color;

            }
        }

        .description {
            font-size: 15px;
            line-height: 25px;
            color: #888;
            text-align: start;
            margin-bottom: 20px;

        }

        .logos {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .logo {
                width: 100px;
                height: 100px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 15px;
                background-color: $alt_1_BG_color;
            }
        }
    }
}

.slider-container {
    width: 100%;
    height: 400px;
    margin: 20px auto 100px;
}
</style>