<template>
  <base-dialog @close="closeDialog" @confifrm="closeDialog" :show="showDialog" title="Error">
    <p> {{ ErrorMsg }} </p>
  </base-dialog>
  <base-dialog :show="isLoading" title="... Loading Solutions ...">
    <base-spinner></base-spinner>
  </base-dialog>
  <div class="layout">
    <div class="header-img">
      <the-header />
    </div>
  </div>

  <base-container>
    <div class="solutions">
      <solution v-for="solution in allSolutions" :key="solution.id" :id="solution.id" :mainImg="solution.main_image"
        :title="solution.name" :description="solution.description"
        :logos="[solution.icon_1, solution.icon_2, solution.icon_3, solution.icon_4]" />
    </div>
  </base-container>
</template>
   
<script>
import Solution from '@/components/solutions/Solution.vue';

export default {
  components: {
    Solution,
  },
  data() {
    return {
      ErrorMsg: '',
      showDialog: false,
      isLoading: false,
      error: null,
    };
  },
  computed: {
    allSolutions() {
      const solutions = this.$store.getters['solutions/solutions'];
      return solutions
    },
  },

  created() {
    this.loadSolutions();
  },
  methods: {
    closeDialog() {
      this.showDialog = false
      this.ErrorMsg = ''
    },
    async loadSolutions() {
      this.isLoading = true;

      try {
        await this.$store.dispatch('solutions/loadSolutions');
      } catch (error) {
        this.showDialog = true
        this.ErrorMsg = error.message || 'Something went wrong!';
      }
      this.isLoading = false;

    },
  },
};
</script>
<style lang="scss" scoped>
@import '../scss/vars';

.layout {
  .header-img {
    background-image: url('../assets/images/Untitled-2-01.jpg');
    background-size: cover;
    margin-bottom: 50px;
    width: 100%;
    height: 400px;
    overflow: hidden;
    border-radius: 0 0 15% 50%/0 0 30% 60%;

    @media (max-width: 767px) {
      border-radius: 0;
      height: 150px;
      overflow: visible;
    }

    position: relative;

    &::before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(90deg, rgba(82, 75, 46, 0.507) 0%, rgba(240, 241, 241, 0.192) 100%);

    }
  }
}


.solutions {
  margin: 50px auto 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
}
</style>