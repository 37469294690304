import HOST from '../const';

export default {
  async loadProjects(context, payload) {
    const response = await fetch(`${HOST}/api/projects/`);
    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(
        responseData.message || "Failed to fetch Projects!"
      );
      throw error;
    }

    const projects = [];
    for (const key in responseData) {
      const project = {
        id: responseData[key].id,
        project_name: responseData[key].project_name,
        project_description: responseData[key].project_description,
        preview_photo: responseData[key].preview_photo,
        tags: responseData[key].tags,
      };
      // console.log("4- project: ", project);

      projects.push(project);
    }
    // console.log("5- project: ", projects);

    context.commit("setProjects", projects);
    // context.commit("setFetchTimestamp");
  },
  async loadProject(context, payload) {
    const response = await fetch(
      `${HOST}/api/projects/${payload.projectId}`
    );
    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(
        responseData.message || "Failed to fetch Projects!"
      );
      throw error;
    }

    const project = {
      id: responseData.id,
      project_name: responseData.project_name,
      project_description: responseData.project_description,
      preview_photo: responseData.preview_photo,
      project_photos: responseData.project_photos,
      tags: responseData.tags,
      location: responseData.location,
      status: responseData.project_status,
      endDate: responseData.end_date,
      startDate: responseData.start_date,
    };
    context.commit("setProject", project);
  },

};
