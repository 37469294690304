<template>
    <div class="slider" :class="{ isLarg: imgIsClicked }" v-if="images.length !== 0">

        <div class="img">
            <img :src="images[sliderNo].image" alt="" @click="imgClicked()">

        </div>
        <div class="arrow">
            <div class="back" @click="prevImg">
                <font-awesome-icon :icon="['fas', 'angle-left']" />
            </div>
            <div class="next" @click="nextImg">
                <font-awesome-icon :icon="['fas', 'angle-right']" />
            </div>
        </div>

    </div>
</template>
    
<script >
export default {
    props: ['images'],
    data() {
        return {
            sliderNo: 0,
            imgIsClicked: false
        }
    },
    computed: {


    },
    methods: {
        imgClicked() {
            this.imgIsClicked = !this.imgIsClicked
            console.log('clicked: ' + this.imgIsClicked)
        },
        prevImg() {
            if (this.sliderNo == 0) {
            } else {
                this.sliderNo = (this.sliderNo - 1)
            }
        },
        nextImg() {
            if (this.sliderNo == this.images.length - 1) {
            } else {
                this.sliderNo = (this.sliderNo + 1)
            }
        },
    },

};
</script>
<style lang="scss" scoped>
@import '../../scss/vars';

.slider {
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &.isLarg {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #ffffffd8;
        z-index: 100;
    }

    .img {
        width: 50%;

        img {
            width: 100%;
            aspect-ratio: 1/1;
            object-fit: contain;
        }

    }

    .back,
    .next {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        border: 1px solid $alt_3_color;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        cursor: pointer;
        text-align: center;
        background-color: $alt_2_color;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
            width: 30px;
            height: 30px;
            transform: translateY(-50%);

        }

        svg {
            font-weight: bold;
            margin: auto;
            color: $alt_3_color;
        }
    }

    .back {
        left: 15%;

        @media (max-width: 767px) {
            left: 5%;
        }
    }

    .next {
        right: 15%;

        @media (max-width: 767px) {
            right: 5%;
        }
    }



}
</style>